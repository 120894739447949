import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import { Helmet } from "react-helmet";
import logoSolidWhite from "../img/signature-multi-white.svg";

const DefaultPage = ({ data, pageContext }) => {
  return (
    <Layout
      headerClassName="bg-accent curve-br"
      headerContent={<span />}
      navbarLogo={logoSolidWhite}
      className="accent-navy"
      headerTitle="News"
    >
      <Helmet titleTemplate="%s">
        <title>News</title>
        <meta property="og:title" content="News" />
      </Helmet>

      <section class="container mt-2 md:mt-8">
        <div class="columns">
          <div class="w-full lg:w-8/12 space-y-4 md:space-y-8">
            <h1 className="font-heading text-3xl lg:text-4xl xl:text-5xl">
              News
            </h1>

            {data.allMarkdownRemark.edges.map(({ node }) => (
              <a
                className="block hover:text-royal"
                href={node.frontmatter.link}
                target="_blank"
                rel="noreferrer"
              >
                <article>
                  <h3 className="font-semibold text-lg lg:text-xl">
                    {node.frontmatter.title}
                  </h3>
                  <p className="lg:text-lg">
                    {node.frontmatter.source} - {node.frontmatter.date}
                  </p>
                </article>
              </a>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DefaultPage;

export const listQuery = graphql`
  query NewsListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "news-item" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "M.D.YY")
            link
            source
          }
        }
      }
    }
  }
`;
